function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Текст скопирован: " + text);
      },
      (err) => {
        console.log("Ошибка копирования: " + err);
      }
    );
  }
  
  window.copyToClipboard = copyToClipboard;
  